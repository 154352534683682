import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getHomeGalery } from "../../DataManager/config";

import Localization from "../Localization/Localize";
import "../Gallery/HomeMuineGalery.css";

const RoomPhotosContainer = () => {
  const [galery, setGalery] = useState("MuineBay");

  useEffect(() => {
    getHomeGalery().then((item) => {
      if (!item) {
        return;
      }
      const data = item.data();
      setGalery(data);
    });
  }, []);

  // let firstHalf = [];
  // rooms.forEach((room, index) => {
  //   const urls = room && room.urls;
  //   if (index < rooms.length / 2) {
  //     firstHalf = firstHalf.concat(urls ?? []);
  //   }
  // });
  return (
    <>
      <div className="section_head">
        <h2 className="section_title">{Localization.localize("roomPhoto")}</h2>
      </div>

      <div className="container">
        <div className="homeGalleryMuiNe">
          <figure className="homeGalleryMuiNe__item homeGalleryMuiNe__item--1 gallery-image">
            <img
              src={galery.urls && galery.urls[0] && galery.urls[0].url}
              alt="Gallery"
              className="homeGalleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={galery.urls && galery.urls[0] && galery.urls[0].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {galery.urls && galery.urls[0] && galery.urls[0].name}
            </div>
          </figure>

          <figure className="homeGalleryMuiNe__item homeGalleryMuiNe__item--2 gallery-image">
            <img
              src={galery.urls && galery.urls[1] && galery.urls[1].url}
              alt="Gallery"
              className="homeGalleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={galery.urls && galery.urls[1] && galery.urls[1].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {galery.urls && galery.urls[1] && galery.urls[1].name}
            </div>
          </figure>

          <figure className="homeGalleryMuiNe__item homeGalleryMuiNe__item--3 gallery-image">
            <img
              src={galery.urls && galery.urls[2] && galery.urls[2].url}
              alt="Gallery"
              className="homeGalleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={galery.urls && galery.urls[2] && galery.urls[2].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {galery.urls && galery.urls[2] && galery.urls[2].name}
            </div>
          </figure>

          <figure className="homeGalleryMuiNe__item homeGalleryMuiNe__item--4 gallery-image">
            <img
              src={galery.urls && galery.urls[3] && galery.urls[3].url}
              alt="Gallery"
              className="homeGalleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={galery.urls && galery.urls[3] && galery.urls[3].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {galery.urls && galery.urls[3] && galery.urls[3].name}
            </div>
          </figure>

          <figure className="homeGalleryMuiNe__item homeGalleryMuiNe__item--5 gallery-image">
            <img
              src={galery.urls && galery.urls[4] && galery.urls[4].url}
              alt="Gallery"
              className="homeGalleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={galery.urls && galery.urls[4] && galery.urls[4].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {galery.urls && galery.urls[4] && galery.urls[4].name}
            </div>
          </figure>
        </div>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    lang: state.lang.currentLang,
  };
})(RoomPhotosContainer);
