import React, { useEffect } from "react";
import { connect } from "react-redux";
import Banner from "../Common/Banner";
import HotServiceContainer from "../Services/HotServiceContainer";
import ServiceDetailContainer from "../Services/ServiceDetailContainer";
import { setServiceDetail as setServiceDetailAction } from "../../redux/actions/service";
import Localization from "../Localization/Localize";

const ServiceDetail = ({ services, currentService, lang, ...others }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const serviceId = others.match.params && others.match.params.id;
  const selectedService = services.find(curPackage => {
    return curPackage.id === serviceId;
  });
  if (!selectedService) return null;
  return (
    <div>
      <Banner name={Localization.localize("serviceBannerTitle")} />
      <div className="container content-area">
        <div className="middle-align">
          <ServiceDetailContainer selectedService={selectedService} />
          <div id="sidebar">
            <HotServiceContainer hotItems={services} />
          </div>{" "}
          <div className="clear"></div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  state => {
    return {
      service: state.muineService.serviceDetail,
      services: state.muineService.list,
      lang: state.lang.currentLang
    };
  },
  dispatch => ({
    setServiceDetail: data => dispatch(setServiceDetailAction(data))
  })
)(ServiceDetail);
