import React from "react";
import { connect } from "react-redux";
import ThreeCollumnsContainer from "../Gallery/ThreeCollumnsContainer";

const ServiceDetailContainer = ({ selectedService, lang }) => {
  return (
    <>
      <div className="site-main singleright" id="sitemain">
        <article
          id="post-101"
          className="single-post post-101 post type-post status-publish format-standard"
        >
          <div className="blog-post-single">
            <div className="post-thumb-full">
              <img
                width="770"
                height="454"
                src={selectedService.banner_url}
                alt=" "
                sizes="(max-width: 770px) 100vw, 770px"
              />
            </div>

            <div className="post-data">
              <header className="entry-header">
                <div className="postmeta">
                  <span className="single-post-meta-cat">Services</span>
                  <span className="single-post-meta-date">Explore</span>
                  <span className="single-post-meta-author">MuiNe Bay</span>
                </div>
                <h3 className="post-title">{selectedService.name[lang]}</h3>
              </header>

              <div className="entry-content">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      selectedService.description &&
                      selectedService.description[lang],
                  }}
                ></div>{" "}
                <blockquote className="align-left">
                  {" "}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: selectedService.content[lang],
                    }}
                  ></div>{" "}
                </blockquote>
                <div className="entry-content">
                  <ThreeCollumnsContainer
                    photos={selectedService.urls}
                    title={selectedService.name[lang]}
                  />
                  <div className="clear" />
                </div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    lang: state.lang.currentLang,
  };
})(ServiceDetailContainer);
