import React from "react";

const BottomContainer = () => {
  return (
    <>
      <div
        className="copyright-wrapper"
        style={{ backgroundColor: "#CE9B51", opacity: "0.3" }}
      >
        <div className="container">
          <div className="copyright-txt">
            <a href="https://www.facebook.com/MuineBay.Resort.PhanThiet">
              Copyright © 2020 Muine Bay Resort
            </a>
            .
          </div>
          <div className="designby">
            <a
              href="https://www.facebook.com/MuineBay.Resort.PhanThiet"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bến Thành Group.
            </a>
          </div>
          <div className="clear" />
        </div>
      </div>
    </>
  );
};

export default BottomContainer;
