import React from "react";
import { connect } from "react-redux";
import Localization from "../Localization/Localize";

const AmenitiesContainer = ({ lang, selectedRoom }) => {
  let howManyPeople = "";
  let roomSize = "";
  let hasBathtub = "";

  const longDescription =
    selectedRoom &&
    selectedRoom.long_description &&
    selectedRoom.long_description[lang];
  const roomView =
    selectedRoom.id === "ocean_panorama"
      ? Localization.localize("oceanTitle")
      : Localization.localize("gradenTitle");

  const yesLocalization = Localization.localize("yesTitle");
  const noLocalization = Localization.localize("noTitle");

  switch (selectedRoom.id) {
    case "superior":
    case "deluxe":
      howManyPeople = "2";
      roomSize = "38 Sqm";
      hasBathtub = noLocalization;
      break;
    case "ocean_panorama":
      howManyPeople = "2";
      roomSize = "41 Sqm";
      hasBathtub = yesLocalization;
      break;
    case "bungalow":
      howManyPeople = "2";
      roomSize = "62 Sqm";
      hasBathtub = yesLocalization;
      break;
    case "family":
      howManyPeople = "2";
      roomSize = "40 Sqm";
      hasBathtub = noLocalization;
      break;
    case "family_suite":
      howManyPeople = "4";
      roomSize = "62 Sqm";
      hasBathtub = yesLocalization;
      break;
    default:
  }

  return (
    <>
      <div className="room-content">
        <div className="room-meta-info">
          <br />
          <h4>{Localization.localize("amenitiesTitle")}</h4>
          <div className="inner">
            <ul className="muine-room-meta">
              <li className="meta-bed">
                <span className="meta-label">
                  {Localization.localize("bedTitle")}
                </span>
                <span className="meta-text double-bed">
                  {selectedRoom &&
                    selectedRoom.bedType &&
                    selectedRoom.bedType[lang]}
                </span>
              </li>

              <li className="meta-adults">
                <span className="meta-label">
                  {Localization.localize("peopleTitle")}
                </span>
                <span className="meta-text">{howManyPeople}</span>
              </li>

              <li className="meta-size">
                <span className="meta-label">
                  {Localization.localize("roomSizeTitle")}
                </span>
                <span className="meta-text">{roomSize}</span>
              </li>

              <li className="meta-view">
                <span className="meta-label">
                  {Localization.localize("viewTitle")}
                </span>
                <span className="meta-text">{roomView}</span>
              </li>
              <li className="meta-wifi">
                <span className="meta-label">Wifi</span>
                <span className="meta-text">{yesLocalization}</span>
              </li>
              <li className="meta-bearkfast">
                <span className="meta-label">
                  {Localization.localize("breakfastTitle")}
                </span>
                <span className="meta-text">{yesLocalization}</span>
              </li>
              <li className="meta-cable_tv">
                <span className="meta-label">
                  {Localization.localize("cableTVTitle")}
                </span>
                <span className="meta-text">{yesLocalization}</span>
              </li>
              <li className="meta-pickup">
                <span className="meta-label">
                  {Localization.localize("transitCarTitle")}
                </span>
                <span className="meta-text">{yesLocalization}</span>
              </li>
              <li className="meta-freebathtub">
                <span className="meta-label">
                  {Localization.localize("bathtubTitle")}
                </span>
                <span className="meta-text">{hasBathtub}</span>
              </li>
              <li className="meta-petsallowed">
                <span className="meta-label">
                  {Localization.localize("petTitle")}
                </span>
                <span className="meta-text">{noLocalization}</span>
              </li>
              <li className="meta-roomservice">
                <span className="meta-label">
                  {Localization.localize("roomServiceTitle")}
                </span>
                <span className="meta-text">{yesLocalization}</span>
              </li>

              <li className="meta-iron">
                <span className="meta-label">Iron</span>
                <span className="meta-text">{noLocalization}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="room-description">
          <h4>{Localization.localize("descriptionTitle")}</h4>

          <div className="content">
            <div
              dangerouslySetInnerHTML={{
                __html: longDescription,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    lang: state.lang.currentLang,
  };
})(AmenitiesContainer);
