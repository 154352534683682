import React from "react";
import { connect } from "react-redux";
import Localization from "../Localization/Localize";

const PackageDetailContainer = ({ selectedPackage, lang }) => {
  return (
    <>
      <div className="site-main singleright" id="sitemain">
        <article
          id="post-101"
          className="single-post post-101 post type-post status-publish"
        >
          <div className="blog-post-single">
            <div className="post-thumb-full">
              <img
                width="770"
                height="454"
                src={selectedPackage.urls && selectedPackage.urls[0]}
                alt=" "
                sizes="(max-width: 770px) 100vw, 770px"
              />
            </div>

            <div className="post-data">
              <header className="entry-header">
                <div className="postmeta">
                  <span className="single-post-meta-cat">Package</span>
                  <span className="single-post-meta-date">Explore</span>
                  <span className="single-post-meta-author">MuiNe Bay</span>
                </div>
                <h3 className="post-title">{selectedPackage.name[lang]}</h3>
                <h6 className="post-title">
                  <b>{selectedPackage.price[lang]}</b>
                </h6>
              </header>

              <div className="entry-content">
                <p>
                  {selectedPackage.description &&
                    selectedPackage.description[lang]}
                </p>
                <p>
                  <b>{Localization.localize("includeTitle")}</b>
                </p>
                <blockquote className="align-left">
                  {" "}
                  {selectedPackage.included &&
                    selectedPackage.included.map((include, index) => {
                      return (
                        <div
                          index={index}
                          key={index}
                          className="blockquoteTag"
                        >
                          <i className="fas fa-plus-circle"></i>{" "}
                          <span>{include[lang]}</span>
                        </div>
                      );
                    })}
                </blockquote>
                <img
                  src={selectedPackage.urls && selectedPackage.urls[1]}
                  alt=""
                  width="300"
                  height="225"
                  className="alignleft size-medium wp-image-20"
                  sizes="(max-width: 300px) 100vw, 300px"
                />
                <p>
                  <b>{Localization.localize("termsAndConditionsTitle")}</b>
                </p>
                <blockquote className="align-left">
                  {selectedPackage.conditions &&
                    selectedPackage.conditions.map((condition, index) => {
                      return (
                        <div
                          index={index}
                          key={index}
                          className="blockquoteTag"
                        >
                          <i className="fas fa-info-circle"></i>{" "}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: condition[lang],
                            }}
                          ></span>
                        </div>
                      );
                    })}{" "}
                </blockquote>

                <p>
                  <b>{Localization.localize("addOnsService")}</b>
                </p>
                <blockquote className="align-left">
                  {selectedPackage.add_ons && selectedPackage.add_ons[lang]}
                </blockquote>
              </div>
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    lang: state.lang.currentLang,
  };
})(PackageDetailContainer);
