import React from "react";
import { createUser } from "../../DataManager/config";

const FormContainer = ({ namePlaceHolder, messagePlaceholder }) => {
  const handleSubmit = async e => {
    e.preventDefault();
    const form = e.target;
    const name = form.elements.name.value;
    const email = form.elements.email.value;
    const message = form.elements.textareaMessage.value;
    const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    if (!validEmail) {
      return window.alert("Please input correct Email!");
    }
    await createUser(name, email, message);
    form.elements.name.value = "";
    form.elements.email.value = "";
    form.elements.textareaMessage.value = "";
  };
  return (
    <>
      <div className="one_half last_column">
        <div className="main-contactform">
          <div role="form" className="muineFormContact" lang="en-US" dir="ltr">
            <div className="screen-reader-response" />
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <span>
                  <input
                    type="text"
                    name="name"
                    size="40"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder={namePlaceHolder}
                  />
                </span>
              </div>

              <div className="form-group">
                <span>
                  <input
                    type="email"
                    name="email"
                    size="40"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Email Address"
                  />
                </span>
              </div>

              <div className="form-group">
                <span>
                  <textarea
                    name="textareaMessage"
                    cols="40"
                    rows="10"
                    aria-invalid="false"
                    placeholder={messagePlaceholder}
                  />
                </span>
              </div>

              <div className="form-group">
                <input type="submit" className="send-btn" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormContainer;
