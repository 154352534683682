import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getGallery } from "../../DataManager/config";
import Banner from "../Common/Banner";
import ExploreGalleryContainer from "./ExploreGalleryContainer";
import Localization from "../Localization/Localize";

const GalleryPage = () => {
  const [galery, setGalery] = useState("MuineBay");

  useEffect(() => {
    getGallery().then((item) => {
      if (!item) {
        return;
      }
      const data = item.data();
      setGalery(data);
    });
  }, []);

  return (
    <div>
      <Banner name={Localization.localize("galleryTitle")} />
      <div className="container content-area">
        <div className="middle-align">
          <div className="site-main sitefull">
            <article
              id="post-26"
              className="post-26 page type-page status-publish"
            >
              <div className="entry-content">
                <div className="clear" />
                <ExploreGalleryContainer photos={galery} />
                <div className="clear" />
              </div>
            </article>{" "}
          </div>
          <div className="clear" />
        </div>
      </div>
    </div>
  );
};

export default connect((state) => {
  return {
    lang: state.lang.currentLang,
  };
})(GalleryPage);
