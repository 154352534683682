import React from "react";
import { connect } from "react-redux";
import "./MuineGalery.css";
import Localization from "../Localization/Localize";

const ExploreGalleryContainer = ({ photos }) => {
  return (
    <>
      <div style={{ height: "50px" }} />
      <div className="custom-heading" style={{ textAlign: "left" }}>
        <h2 style={{ color: "#242c3a" }}>
          <span>MUINE BAY RESORT</span>
          {Localization.localize("exploreGallerySubtile")}
        </h2>
      </div>
      <div className="container">
        <div className="galleryMuiNe">
          <figure className="galleryMuiNe__item galleryMuiNe__item--1 gallery-image">
            <img
              src={photos.urls && photos.urls[0] && photos.urls[0].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[0] && photos.urls[0].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[0] && photos.urls[0].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--2 gallery-image">
            <img
              src={photos.urls && photos.urls[1] && photos.urls[1].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[1] && photos.urls[1].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[1] && photos.urls[1].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--3 gallery-image">
            <img
              src={photos.urls && photos.urls[2] && photos.urls[2].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[2] && photos.urls[2].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[2] && photos.urls[2].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--4 gallery-image">
            <img
              src={photos.urls && photos.urls[3] && photos.urls[3].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[3] && photos.urls[3].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[3] && photos.urls[3].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--5 gallery-image">
            <img
              src={photos.urls && photos.urls[4] && photos.urls[4].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[4] && photos.urls[4].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[4] && photos.urls[4].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--6 gallery-image">
            <img
              src={photos.urls && photos.urls[5] && photos.urls[5].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[5] && photos.urls[5].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[5] && photos.urls[5].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--7 gallery-image">
            <img
              src={photos.urls && photos.urls[6] && photos.urls[6].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[6] && photos.urls[6].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[6] && photos.urls[6].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--8 gallery-image">
            <img
              src={photos.urls && photos.urls[7] && photos.urls[7].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[7] && photos.urls[7].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[7] && photos.urls[7].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--9 gallery-image">
            <img
              src={photos.urls && photos.urls[8] && photos.urls[8].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[8] && photos.urls[8].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[8] && photos.urls[8].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--10 gallery-image">
            <img
              src={photos.urls && photos.urls[9] && photos.urls[9].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[9] && photos.urls[9].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[9] && photos.urls[9].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--11 gallery-image">
            <img
              src={photos.urls && photos.urls[10] && photos.urls[10].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[10] && photos.urls[10].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[10] && photos.urls[10].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--12 gallery-image">
            <img
              src={photos.urls && photos.urls[11] && photos.urls[11].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[11] && photos.urls[11].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[11] && photos.urls[11].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--13 gallery-image">
            <img
              src={photos.urls && photos.urls[12] && photos.urls[12].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[12] && photos.urls[12].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[12] && photos.urls[12].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--14 gallery-image">
            <img
              src={photos.urls && photos.urls[13] && photos.urls[13].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[13] && photos.urls[13].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[13] && photos.urls[13].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--15 gallery-image">
            <img
              src={photos.urls && photos.urls[14] && photos.urls[14].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[14] && photos.urls[14].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[14] && photos.urls[14].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--16 gallery-image">
            <img
              src={photos.urls && photos.urls[15] && photos.urls[15].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[15] && photos.urls[15].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[15] && photos.urls[15].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--17 gallery-image">
            <img
              src={photos.urls && photos.urls[16] && photos.urls[16].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[16] && photos.urls[16].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[16] && photos.urls[16].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--18 gallery-image">
            <img
              src={photos.urls && photos.urls[17] && photos.urls[17].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[17] && photos.urls[17].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[17] && photos.urls[17].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--19 gallery-image">
            <img
              src={photos.urls && photos.urls[18] && photos.urls[18].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[18] && photos.urls[18].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[18] && photos.urls[18].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--20 gallery-image">
            <img
              src={photos.urls && photos.urls[19] && photos.urls[19].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[19] && photos.urls[19].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[19] && photos.urls[19].name}
            </div>
          </figure>

          <figure className="galleryMuiNe__item galleryMuiNe__item--21 gallery-image">
            <img
              src={photos.urls && photos.urls[20] && photos.urls[20].url}
              alt="Gallery"
              className="galleryMuiNe__img"
            />
            <div className="gallery-icon">
              <a
                href={photos.urls && photos.urls[20] && photos.urls[20].url}
                data-fancybox="images"
              >
                {" "}
              </a>
            </div>
            <div className="caption">
              {photos.urls && photos.urls[20] && photos.urls[20].name}
            </div>
          </figure>
        </div>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    lang: state.lang.currentLang,
  };
})(ExploreGalleryContainer);
