import React from "react";
import { connect } from "react-redux";
import Banner from "../Common/Banner";
import ContentContainer from "./ContentContainer";
import FormContainer from "./FormContainer";

import Localization from "../Localization/Localize";

const Contact = ({ lang }) => {
  return (
    <div>
      <Banner name={Localization.localize("pageTitle")} />

      <div className="content-area">
        <div className="middle-align">
          <div className="site-main sitefull">
            <div className="container">
              <div className="contact-content">
                <ContentContainer
                  title={Localization.localize("contactTitle")}
                  description={Localization.localize("contactDescription")}
                  addressTitle={Localization.localize("resortAddressTitle")}
                  representativeAddress={Localization.localize(
                    "representativeAddress"
                  )}
                  phoneTitle={Localization.localize("phoneTitle")}
                  resortAddress={Localization.localize("resortAddress")}
                  addressOffice={Localization.localize("officeAddressTitle")}
                />
                <FormContainer
                  namePlaceHolder={Localization.localize(
                    "muineNamePlaceholder"
                  )}
                  messagePlaceholder={Localization.localize(
                    "messagePlaceholder"
                  )}
                  sendInfo={Localization.localize("sendButton")}
                />
                <div className="clear" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gmap">
        <iframe
          title="MuiNe Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10374.343972008894!2d108.28243987873981!3d10.92246248636524!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31768f340fe257a5%3A0x81429d620f625da3!2sMuine%20Bay%20Resort!5e0!3m2!1sen!2s!4v1598264439467!5m2!1sen!2s"
          width="98%"
          height="600"
          frameBorder="0"
        />
      </div>

      <div className="clear" />
    </div>
  );
};

export default connect((state) => {
  return {
    lang: state.lang.currentLang,
  };
})(Contact);
