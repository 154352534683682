import vn from "./vn";
import en from "./en";

class Localization {
  currentLangBundle = en;

  setLang(value) {
    switch (value) {
      case "en":
        this.currentLangBundle = en;
        break;
      case "vn":
        this.currentLangBundle = vn;
        break;
      default:
        break;
    }
  }

  localize(key) {
    return this.currentLangBundle[key] ?? "";
  }
}
export default new Localization();
