import React, { useRef } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

const Carousel = ({ data }) => {
  const slider = useRef();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const handleClickThumbnail = index => () => {
    slider.current.slickGoTo(index);
  };

  return (
    <div className="carousel-wrapper">
      <Slider ref={slider} {...settings}>
        {data.map((item, index) => {
          return (
            <img
              className="carousel-item"
              key={index}
              src={item}
              alt="room_carousel_thumbnail"
            />
          );
        })}
      </Slider>
      <div className="list-thumbnails">
        {data.map((item, index) => {
          return (
            <img
              key={index}
              src={item}
              alt="room_item_thumbnail"
              className="thumbnail_item"
              onClick={handleClickThumbnail(index)}
            />
          );
        })}
      </div>
    </div>
  );
};
export default Carousel;
