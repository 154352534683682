import React from "react";
import { connect } from "react-redux";
import Localization from "../Localization/Localize";

const Intro = ({ lang }) => {
  return (
    <section className="">
      <div className="container">
        <div>
          <div className="section_head">
            <h2 className="section_title">
              Mui Ne, Binh Thuan Province<span></span>
            </h2>
            <p>{Localization.localize("introDescription")}</p>
          </div>
          <div className="intro-section">
            <ul className="list-5">
              <li>{Localization.localize("firstIntroSection")}</li>
              <li>{Localization.localize("secondIntroSection")}</li>
              <li> {Localization.localize("thirdIntroSection")}</li>
              <li>{Localization.localize("fourthIntroSection")}</li>
            </ul>
            <div
              className="divide"
              style={{ width: "1px", background: "black" }}
            ></div>
            <ul className="list-5">
              <li>{Localization.localize("fifthIntroSection")}</li>
              <li>{Localization.localize("sixthIntroSection")}</li>
              <li>{Localization.localize("seventhIntroSection")}</li>
              <li>{Localization.localize("eighthIntroSection")}</li>
            </ul>
          </div>

          <div className="one_half  last_column">
            <div
              id="image-slider"
              className="owl-carousel owl-theme"
              style={{ opacity: "1", display: "block" }}
            ></div>
          </div>
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    </section>
  );
};

export default connect((state) => {
  return {
    lang: state.lang.currentLang,
  };
})(Intro);
