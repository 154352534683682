import React from "react";
import { throttle } from "lodash";

export default class VideoBackground extends React.Component {
  state = {
    videoHeight: "100%",
    player: null,
    isMuted: true,
  };

  myRef = React.createRef();

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    setTimeout(() => this.setState({ played: true }), 3000);
  }

  handleResize = throttle(() => {
    this.setState({
      videoWidth: `${window.innerWidth}px`,
      videoHeight: `${(window.innerWidth * 9) / 16}px`,
    });
  }, 100);

  render() {
    return (
      <div className="home-wrapper">
        <div className={this.state.className}>
          <div
            className={`muine-video-wrapper ${
              this.state.played ? "hide-wrapper" : ""
            }`}
            style={{
              height: this.state.videoHeight,
              width: this.state.videoWidth,
            }}
          >
            <div className="muteVideo">
              <i
                style={{
                  color: "white",
                }}
                onClick={() => {
                  this.myRef.current.muted = !this.myRef.current.muted;
                  this.setState(() => ({
                    isMuted: this.myRef.current.muted,
                  }));
                }}
                className={
                  !this.state.isMuted
                    ? "fas fa-volume-up"
                    : "fas fa-volume-mute"
                }
              ></i>
            </div>

            <video
              ref={this.myRef}
              width="100%"
              height="100%"
              autoPlay
              loop
              muted
              playsInline
            >
              <source src="/muinevideobackground.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    );
  }
}
