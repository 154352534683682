import React, { memo } from "react";
import { Link } from "react-router-dom";

const Item = ({ name, pathname, currentPath }) => {
  return (
    <>
      <li
        id="menu-item-65"
        className={`menu-item menu-item-type-custom menu-item-object-custom ${
          pathname === currentPath ? "current-menu-item current_page_item" : ""
        } menu-item-home menu-item-65`}
      >
        <Link to={pathname}>{name}</Link>
      </li>
    </>
  );
};

export default memo(Item);
