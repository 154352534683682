import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/auth";
import format from "date-fns/format";

// var firebaseConfig = {
//   apiKey: "AIzaSyA8bcIcaSX4-oFirpwouIdeOXtG8zMCHnE",
//   authDomain: "mui-ne-8a8c4.firebaseapp.com",
//   databaseURL: "https://mui-ne-8a8c4.firebaseio.com",
//   projectId: "mui-ne-8a8c4",
//   storageBucket: "mui-ne-8a8c4.appspot.com",
//   messagingSenderId: "355654476263",
//   appId: "1:355654476263:web:be3ec4efd114447b8e1d42",
//   measurementId: "G-RG9ZEDCE3Q",
// };

var firebaseConfig = {
  apiKey: "AIzaSyBqVrxSMpMVrqfQvwzq53cyek42iNfDebQ",
  authDomain: "muine-bay-resort.firebaseapp.com",
  databaseURL: "https://muine-bay-resort.firebaseio.com",
  projectId: "muine-bay-resort",
  storageBucket: "muine-bay-resort.appspot.com",
  messagingSenderId: "767233971477",
  appId: "1:767233971477:web:dbe26a3e495004be50051c",
  measurementId: "G-VSVNX3HTFF",
};

firebase.initializeApp(firebaseConfig);
window.fbAnalytics = firebase.analytics();

const db = firebase.firestore();

export function createUser(nanmeValue, emailValue, messageValue) {
  return db
    .collection("User Contact")
    .add({
      name: nanmeValue,
      email: emailValue,
      message: messageValue,
      date: format(new Date(), "dd/MM/yyyy"),
    })
    .then(function (docRef) {})
    .catch(function (error) {});
}

export function getUser() {
  return db
    .collection("users")
    .doc("GoH7erzdjkKsbZMhTzet")
    .get()
    .catch(function (error) {});
}

export function getFeatureRooms() {
  return db
    .collection("Feature Rooms")
    .doc("muineFeatureRooms")
    .get()
    .catch(function (error) {});
}

export function getAbout() {
  return db
    .collection("Muine About")
    .doc("muineAbout")
    .get()
    .catch(function (error) {});
}

export function getRooms() {
  return db
    .collection("Rooms")
    .get()
    .catch(function (error) {});
}

export function getMuineRooms() {
  return db
    .collection("Muine Rooms")
    .get()
    .catch(function (error) {});
}

export function getMuineServices() {
  return db
    .collection("Muine Services")
    .get()
    .catch(function (error) {});
}

export function getPackages() {
  return db
    .collection("Muine Packages")
    .get()
    .catch(function (error) {});
}

export function getGallery() {
  return db
    .collection("Muine Gallery")
    .doc("muine_gallery")
    .get()
    .catch(function (error) {});
}

export function getHomeGalery() {
  return db
    .collection("Muine Home Gallery")
    .doc("muine_home_gallery")
    .get()
    .catch(function (error) {});
}
