import React from "react";
import RoomPhotosContainer from "./RoomPhotosContainer";

const RoomsPreview = () => {
  return (
    <section className="">
      <div className="container">
        <div
          className="wow fadeInUp"
          data-wow-duration="1s"
          style={{
            visibility: "visible",
            animationDuration: "1s",
            animationName: "fadeInUp"
          }}
        >
          <RoomPhotosContainer />
          <div className="clear"></div>
        </div>
        <div className="clear"></div>
      </div>
    </section>
  );
};

export default RoomsPreview;
