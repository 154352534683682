import React, { useEffect } from "react";
import { connect } from "react-redux";
import Banner from "../Common/Banner";
import HotPackageContainer from "../Packages/HotPackageContainer";
import PackageDetailContainer from "./PackageDetailContainer";
import { setPackageDetail as setPackageDetailAction } from "../../redux/actions/package";
import Localization from "../Localization/Localize";

const PackageDetail = ({ packages, currentPackage, lang, ...others }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const packageId = others.match.params && others.match.params.id;
  const selectedPackage = packages.find(currentPackage => {
    return currentPackage.id === packageId;
  });
  if (!selectedPackage) return null;

  return (
    <div>
      <Banner name={Localization.localize("packageTitle")} />
      <div className="container content-area">
        <div className="middle-align">
          <PackageDetailContainer selectedPackage={selectedPackage} />
          <div id="sidebar">
            <HotPackageContainer hotItems={packages} />
          </div>{" "}
          <div className="clear"></div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  state => {
    return {
      package: state.muinePackage.packageDetail,
      packages: state.muinePackage.list,
      lang: state.lang.currentLang
    };
  },
  dispatch => ({
    setPackageDetail: data => dispatch(setPackageDetailAction(data))
  })
)(PackageDetail);
