import React, { useCallback } from "react";
import { connect } from "react-redux";
import { changeLanguage } from "../../redux/actions/lang";
import Localization from "../Localization/Localize";

const TopBar = ({ dispatch, lang }) => {
  const handleChangeLanguage = useCallback(() => {
    if (lang === "vn") {
      return dispatch(changeLanguage("en"));
    }
    return dispatch(changeLanguage("vn"));
  }, [dispatch, lang]);

  return (
    <>
      <div className="tp-header-left">
        <ul>
          <li className="iconadd">{Localization.localize("resortAddress")}</li>
          <li className="iconnum">
            <a href="tel:+84 252 222 0222">+84 252 222 0222</a>
          </li>
        </ul>
      </div>

      <div className="tp-header-right">
        <div className="social-icons">
          <a
            style={{ backgroundColor: "" }}
            href="https://www.facebook.com/MuineBay.Resort.PhanThiet"
            target="_blank"
            rel="noopener noreferrer"
            title="facebook"
          >
            <i className="fab fa-facebook-f"></i>
          </a>

          <a
            style={{ backgroundColor: "" }}
            href="https://www.instagram.com/muinebay_resort"
            target="_blank"
            rel="noopener noreferrer"
            title="instagram"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            style={{ backgroundColor: "" }}
            href="https://www.youtube.com/channel/UCaYQuZi2NxNJd6P34eAUe2w?view_as=subscriber"
            target="_blank"
            rel="noopener noreferrer"
            title="youtube"
          >
            <i className="fab fa-youtube"></i>
          </a>
          <a
            style={{ backgroundColor: "" }}
            href="https://www.tripadvisor.com/Hotel_Review-g1009804-d2075996-Reviews-Muine_Bay_Resort-Mui_Ne_Phan_Thiet_Binh_Thuan_Province.html"
            target="_blank"
            rel="noopener noreferrer"
            title="tripadvisor"
          >
            <i className="fab fa-tripadvisor"></i>
          </a>

          <a
            style={{ backgroundColor: "" }}
            href="#muine"
            title="language"
            onClick={handleChangeLanguage}
          >
            <i
              className="fas fa-language fa"
              style={{
                fontSize: "1.6em",
                transform: "translate(5px, 4px)",
              }}
            />
          </a>
        </div>{" "}
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    lang: state.lang.currentLang,
  };
})(TopBar);
