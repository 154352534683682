import React from "react";
import Services from "./Services/Services";
import Rooms from "./Rooms/Rooms";
import RoomsPreview from "../components/Rooms/RoomsPreview";
import Intro from "./Introduction/Intro";
import VideoBackground from "./Video/VideoBackground";

const Home = () => {
  return (
    <>
      <VideoBackground />
      <Intro />
      <Services />
      <Rooms />
      <RoomsPreview />
    </>
  );
};

export default Home;
