import React from "react";
import slide1Img from "../../images/about/about1.jpg";
import slide2Img from "../../images/about/about2.jpg";

const WelcomeContainer = ({
  welcomeTitle,
  welcomeSubtitle,
  aboutTitle,
  vision,
  visionTitle,
  missionTitle,
  mission,
}) => {
  return (
    <>
      <div className="">
        <div className="custom-heading" style={{ textAlign: "center" }}>
          <h2 style={{ color: "#242c3a" }}>
            <span>{welcomeTitle} </span> {welcomeSubtitle}{" "}
          </h2>
        </div>
        <div
          style={{ textAlign: "center" }}
          dangerouslySetInnerHTML={{
            __html: aboutTitle,
          }}
        ></div>

        <div style={{ height: "20px" }}></div>
        <p style={{ textAlign: "center" }}></p>
      </div>
      <div className="clear"></div>
      <div style={{ height: "50px" }}></div>
      <div className="one_half ">
        <div href="#" className="image_info">
          <div className="image_info_thumb">
            <img src={slide1Img} alt="" title="" />
          </div>
          <div className="image_info_content">
            <h3>{visionTitle} </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: vision,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="one_half  last_column">
        <div href="#" className="image_info">
          <div className="image_info_thumb">
            <img src={slide2Img} alt="" title="" />
          </div>
          <div className="image_info_content">
            <h3>{missionTitle}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: mission,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeContainer;
