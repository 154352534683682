import Localization from "../../components/Localization/Localize";

const initState = {
  currentLang: "en"
};
function langReducer(state = initState, action) {
  if (action.type === "CHANGE_LANGUAGE") {
    let lang = action.data;
    Localization.setLang(lang);
    return {
      ...state,
      currentLang: lang
    };
  }

  return state;
}
export default langReducer;
