import { combineReducers } from "redux";
import lang from "./lang";
import room from "./room";
import muinePackage from "./package";
import muineService from "./service";

export default combineReducers({
  lang,
  room,
  muinePackage,
  muineService
});
