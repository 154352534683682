import React from "react";
import { connect } from "react-redux";
import Banner from "../Common/Banner";
import PackagesContainer from "./PackagesContainer";
import Localization from "../Localization/Localize";

const PackagesPage = ({ packages, lang }) => {
  return (
    <div>
      <Banner name={Localization.localize("packageTitle")} />
      <div className="container content-area">
        <div className="middle-align">
          <div className="site-main sitefull" id="sitemain">
            {packages &&
              packages.map((currentPackage, index) => {
                if (!currentPackage.isActive) {
                  return null;
                }
                const isLast = (index + 1) % 2 === 0;
                return (
                  <PackagesContainer
                    isLast={isLast}
                    currentPackage={currentPackage}
                    idx={index}
                    key={index}
                  />
                );
              })}
          </div>
          <div className="clear"></div>
        </div>
      </div>
    </div>
  );
};

export default connect(state => {
  return {
    package: state.muinePackage.packageDetail,
    packages: state.muinePackage.list,
    lang: state.lang.currentLang
  };
})(PackagesPage);
