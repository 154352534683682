import React, { useEffect } from "react";

const ScrollToTop = ({ children }) => {
  const handleScroll = (e) => {
    const offsetBottom = window.pageYOffset;
    if (!document.getElementById("scrollTop")) {
      return;
    }
    if (
      offsetBottom + document.documentElement.clientHeight >=
      0.8 * document.documentElement.scrollHeight
    ) {
      document.getElementById("scrollTop").style.opacity = "1";
    } else {
      document.getElementById("scrollTop").style.opacity = "0";
    }
  };

  const style = {
    opacity: "0",
    position: "fixed",
    zIndex: 1,
    bottom: 360,
    right: 30,
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div id="scrollTop" onClick={scrollTop} style={style}>
        <i
          className="fas fa-angle-double-up fa-3x"
          style={{ color: "#ce9b51" }}
        ></i>
      </div>
      {children}
    </>
  );
};

export default ScrollToTop;
