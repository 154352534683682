export default {
  galleryTitle: "HÌNH ẢNH",

  homeLinkTitle: "Trang chủ",

  exploreGallerySubtile: "Hình Ảnh",

  packageTitle: "GÓI SẢN PHẨM",

  priceSubtitle: "Phòng/Đêm",

  priceSubtitleV2: "phòng / đêm",

  discountDescription: "để nhận giá ưu đãi.",

  secondDiscountDescription: "Giảm từ 5-15% cho khách hàng quay lại.",

  roomPhoto: "Khám phá Mũi Né",

  roomBannerTitle: "Phòng MUINE BAY",

  serviceBannerTitle: "DỊCH VỤ",

  introDescription:
    "Sau sự kiện Nhật thực toàn phần năm 1995, địa danh Mũi Né được phát hiện hết sức độc đáo, có thể ví như “nàng công chúa ngủ trong rừng” và các nhà đầu tư du lịch đã đánh thức “nàng công chúa” đang mơ ngủ kia thức tỉnh, đem những vẻ đẹp thiên nhiên hoang sơ với biển xanh, cát trắng, nắng vàng giới thiệu với đông đảo khách thập phương.",

  firstIntroSection: "5h di chuyển từ Sài Gòn",
  secondIntroSection: "Biển xanh, cát trắng, nắng vàng",
  thirdIntroSection: "Thiên đường của những Resort",
  fourthIntroSection: "Ẩm thực địa phương trù phú",
  fifthIntroSection: "Cung đường đẹp nhất Việt Nam",
  sixthIntroSection: "Tiểu sa mạc hoang sơ, kỳ vĩ",
  seventhIntroSection: "Nếp sống giản dị của dân làng chài",
  eighthIntroSection: "Con người chân chất, hòa nhã",

  // **** MENU SECTION ****
  menuHome: "Trang chủ",

  menuRoom: "Phòng",

  menuService: "Dịch vụ",

  menuPackage: "Gói sản phẩm",

  menuGallery: "Hình ảnh",

  menuAbout: "Về chúng tôi",

  menuContact: "Liên hệ",

  menuSubSlogan: "Chào mừng đến Muine Bay Resort",

  bookNow: "ĐẶT PHÒNG",

  usefulLink: "Đường Dẫn",

  // **** MENU SECTION ****

  // **** CONTACT SECTION ****
  aboutTitle: "Về chúng tôi",

  officeAddressTitle: "Địa chỉ Văn phòng đại diện",

  resortAddressTitle: "Địa chỉ Resort",

  pageTitle: "Liên hệ",

  contactTitle: "Liên hệ",

  contactDescription:
    "Tọa lạc ngay vịnh Mũi Né xinh đẹp và trông ra đảo Hòn Lao hoang sơ giữa biển xanh, Muine Bay Resort gồm 212 phòng nghỉ theo tiêu chuẩn 4 sao được trang bị nội thất hiện đại, sang trọng",

  followTitle: "Theo Dõi",

  messagePlaceholder: "Tin nhắn",

  muineNamePlaceholder: "Tên",

  phoneTitle: "Điện thoại",

  representativeAddress:
    "Văn phòng đại diện: 203 Đinh Tiên Hoàng, Phường Tân Đinh, Quận 1, Hồ Chí Minh, Việt Nam",

  resortAddress:
    "Khu phố 14, Phường Mũi Né , Tp. Phan Thiết, Tỉnh Bình Thuận, Việt Nam",

  sendButton: "Gữi thông tin",

  // ********* COMMON INFO ROOM ************
  additionalOffer: "Dịch vụ kèm thêm",

  amenitiesTitle: "Tiện Nghi",

  awardsTitle: "Giải Thưởng",

  bathtubTitle: "Bồn Tắm",

  bedTitle: "Giường",

  hotDealsTitle: "Phòng bán chạy",

  breakfastTitle: "Ăn Sáng",

  cableTVTitle: "Tivi",

  childPolicyTitle: "Chính sách trẻ em",

  descriptionTitle: "Mô tả",

  discountTitle: "Giảm Giá",

  gradenTitle: "Vườn",

  noTitle: "Không",

  oceanTitle: "Biển",

  peopleTitle: "Người",

  petTitle: "Thú Cưng",

  roomServiceTitle: "Dịch Vụ Phòng",

  roomSizeTitle: "Diện Tích",

  roomSubTitle:
    '"Phòng ốc tuyệt đẹp, không gian xanh mát, hương vị biển nhẹ nhàng thư thái, từng chi tiết nhỏ đều phảng phất một thoáng hương Chăm… Không ngờ những điều tưởng chừng rời rạc trên lại có thể kết hợp với nhau một cách tuyệt vời như vậy tại Muine Bay resort"',

  roomSubTitleComment:
    "Trích lời một nghệ sỹ - Doanh nhân nổi tiếng, đã lưu trú vào 2012",

  roomTitle: "Phòng",

  transitCarTitle: "Xe Đưa Đón",

  viewTitle: "Hướng Nhìn",

  yesTitle: "Có",

  addOnsService: "Dịch vụ kèm theo: ",
  includeTitle: "Dịch vụ bao gồm:",
  termsAndConditionsTitle: "Điều kiện áp dụng:",

  readMoreText: "Xem thêm",
};
