import React from "react";
import { connect } from "react-redux";
import { setServiceDetail as setServiceDetailAction } from "../../redux/actions/service";
import { Link } from "react-router-dom";
import Localization from "../Localization/Localize";

const ServicesContainer = ({
  isLast,
  currentService,
  keyIdx,
  lang,
  setServiceDetail,
}) => {
  const setDetail = (data) => {
    setServiceDetail(data);
  };
  return (
    <div
      key={keyIdx}
      className={isLast ? "blog-post-repeat lastcols" : "blog-post-repeat"}
    >
      <article
        id="post-101"
        className="post-101 post type-post status-publish format-standard"
      >
        <div className="post-thumb">
          <Link
            to={`/serviceDetail/${currentService.id}`}
            onClick={() => setDetail(currentService.id)}
          >
            <img alt="MUINE BAY" src={currentService.banner_url} />
            <div className="post-overlay"></div>
          </Link>
        </div>
        <div className="post-data">
          <header className="entry-header">
            <h3 className="post-title">
              <Link
                className="room-title yellow-text"
                to={`/serviceDetail/${currentService.id}`}
                onClick={() => setDetail(currentService.id)}
              >
                {currentService.name && currentService.name[lang]}
              </Link>
            </h3>
          </header>

          <div className="entry-summary">
            <p>
              {currentService.short_description &&
                currentService.short_description[lang]}
            </p>
          </div>
          <div className="post_bottom">
            <div className="post_bottom_left">
              <div className="postmeta">
                <Link
                  className="yellow-text"
                  to={`/serviceDetail/${currentService.id}`}
                  onClick={() => setDetail(currentService.id)}
                >
                  {Localization.localize("readMoreText")}
                </Link>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>{" "}
        <div className="clear"></div>
      </article>
    </div>
  );
};

export default connect(
  (state) => {
    return {
      service: state.muineService.serviceDetail,
      services: state.muineService.list,
      lang: state.lang.currentLang,
    };
  },
  (dispatch) => ({
    setServiceDetail: (data) => dispatch(setServiceDetailAction(data)),
  })
)(ServicesContainer);
