import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RoomGallery from "../RoomGallery";
import Banner from "../Common/Banner";
import AmenitiesContainer from "./AmenitiesContainer";
import OptionalPackagesContainer from "./OptionalPackagesContainer";
import HotDealContainer from "./HotDealContainer";
import HotRoomContainer from "../Rooms/HotRoomContainer";
import Localization from "../Localization/Localize";

const RoomDetailPage = ({ rooms, lang, ...others }) => {
  const roomId = others.match.params && others.match.params.id;
  const roomIdDeal = "ocean_panorama";
  const selectedRoom = rooms.find((room) => {
    return room.id === roomId;
  });

  const selectedRoomDeal = rooms.find((room) => {
    return room.id === roomIdDeal;
  });
  if (!selectedRoom) return null;
  return (
    <div className="muine-single">
      <Banner
        name={selectedRoom && selectedRoom.name && selectedRoom.name[lang]}
      />
      <div className="container content-area">
        <div className="middle-align">
          <div>
            <div>
              <div className="site-main" id="sitemain">
                <div>
                  <div>
                    <div>
                      <i
                        className="fas fa-chevron-left"
                        style={{ color: "#ce9b51", marginRight: "5px" }}
                      ></i>
                      <Link className="yellow-text" to="/rooms">
                        Muine Bay Rooms
                      </Link>
                    </div>
                    <div className="muine-main">
                      <h1 className="room-title">
                        {selectedRoom &&
                          selectedRoom.name &&
                          selectedRoom.name[lang]}
                      </h1>
                      <div className="muine-price muine-room-price">
                        <span className="price-value">
                          <b
                            data-thousand=","
                            data-decimal="."
                            data-numberdecimal="1"
                            data-format="0,0.0"
                            data-price={
                              selectedRoom &&
                              selectedRoom.price &&
                              selectedRoom.price[lang]
                            }
                          >
                            {selectedRoom &&
                              selectedRoom.price &&
                              selectedRoom.price[lang]}
                          </b>
                        </span>
                        <span className="price-title">
                          {Localization.localize("priceSubtitleV2")}
                        </span>{" "}
                      </div>

                      <RoomGallery data={selectedRoom && selectedRoom.urls} />
                      <AmenitiesContainer selectedRoom={selectedRoom} />
                      <OptionalPackagesContainer selectedRoom={selectedRoom} />
                    </div>
                  </div>
                </div>
              </div>
              <div id="sidebar" className="muine-sidebar">
                <aside className="widget">
                  <a
                    href="https://be.synxis.com/?adult=1&arrive=2020-12-11&chain=9005&child=0&config=mbrsphth&currency=VND&depart=2020-12-12&fbclid=IwAR3tznENRzIDQLBfpYpb86dHYQnyDyCBdmbFiNIx2MLOYM7ZBPgRS4UiMBg&hotel=60715&level=hotel&locale=en-US&rooms=1&theme=mbrsphth"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: "block", textAlign: "center" }}
                    className="muine-button-submit button button-primary hover-link"
                  >
                    {Localization.localize("bookNow")}
                  </a>
                </aside>

                <aside className="widget">
                  <HotRoomContainer hotRooms={rooms} />
                </aside>

                <aside className="widget">
                  <HotDealContainer
                    detailLink="/rooms/ocean_panorama"
                    hotDealRoom={selectedRoomDeal}
                  />
                </aside>
              </div>
              <div className="clear"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state) => {
  return {
    room: state.room.roomDetail,
    rooms: state.room.list,
    lang: state.lang.currentLang,
  };
})(RoomDetailPage);
