import React from "react";
import { connect } from "react-redux";
import { setRoomDetail as setRoomDetailAction } from "../../redux/actions/room";
import { Link } from "react-router-dom";
import Localization from "../Localization/Localize";

const Rooms = ({ lang, rooms, setRoomDetail }) => {
  const setDetail = (data) => {
    setRoomDetail(data);
  };

  return (
    <section style={{ backgroundColor: "#f6f6f6" }}>
      <div className="container">
        <div
          className="wow fadeInUp"
          data-wow-duration="1s"
          style={{
            visibility: "visible",
            animationDuration: "1s",
            animationName: "fadeInUp",
          }}
        >
          <div className="section_head">
            <h2 className="section_title">
              {Localization.localize("roomTitle")}
              <span />
            </h2>
            <p
              style={{
                fontFamily: "'Dancing Script', cursive",
                fontSize: "24px",
                fontWeight: "lighter",
              }}
            >
              {Localization.localize("roomSubTitle")}{" "}
            </p>
            <div>{Localization.localize("roomSubTitleComment")} </div>
          </div>

          {rooms &&
            rooms.map((room, index) => {
              const isLast = index !== 0 && (index + 1) % 3 === 0;
              return (
                <div
                  key={index}
                  className={isLast ? "one_third last_column" : "one_third"}
                >
                  <div className="muine-room">
                    <div className="muine-room-thumb">
                      <img
                        alt="MUINE BAY"
                        src={room && room.urls && room.urls[0]}
                      />
                    </div>
                    <div className="muine-room-cont">
                      <div className="muine-room-cont-wrap">
                        <Link
                          to={`/rooms/${room.id}`}
                          onClick={() => setDetail(room.id)}
                        >
                          <h3 className="yellow-text">
                            {room.name && room.name[lang]}
                          </h3>
                        </Link>
                        <p>
                          {" "}
                          {room.short_description &&
                            room.short_description[lang]}{" "}
                        </p>
                        <div className="muine-room-price">
                          {room.price && room.price[lang]}
                          <span className="price-title">
                            {Localization.localize("priceSubtitle")}
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clear" />
                </div>
              );
            })}

          {/* Next */}
          <div className="clear" />
        </div>
        <div className="clear" />
      </div>
    </section>
  );
};

export default connect(
  (state) => {
    return {
      lang: state.lang.currentLang,
      rooms: state.room.list,
    };
  },
  (dispatch) => ({
    setRoomDetail: (data) => dispatch(setRoomDetailAction(data)),
  })
)(Rooms);
