import React, { useEffect } from "react";

import Menu from "./components/MenuItem/Menu";
import Home from "./components/Home";
import Footer from "./components/Footer/Footer";
import ContactPage from "./components/Contact/ContactPage";
import GalleryPage from "./components/Gallery/GalleryPage";
import RoomsPage from "./components/Rooms/RoomsPage";
import AboutPage from "./components/About/AboutPage";
import ServicesPage from "./components/Services/ServicesPage";
import PackagesPage from "./components/Packages/PackagesPage";
import PackageDetailPage from "./components/Packages/PackageDetail";
import RoomDetailPage from "./components/Rooms/RoomDetailPage";
import ServiceDetailPage from "./components/Services/ServiceDetail";

import "./css/muine-main.css";
import "./css/muine-custom.css";
import "./css/muine-responsive.css";
import "./css/responsive.css";
import "./css/muine-room.css";
import "./css/muine-iframe.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { setRooms as setRoomsAction } from "./redux/actions/room";
import { setServices as SetServicesAction } from "./redux/actions/service";
import { setPackages as setPackagesAction } from "./redux/actions/package";

import {
  getMuineRooms,
  getMuineServices,
  getPackages,
} from "./DataManager/config";

import ScrollToTop from "./components/Accessibility/ScrollToTop";
import FacebookMessager from "./components/Accessibility/FacebookMessager";

const changePath = (path) => {
  return process.env.PUBLIC_URL + path;
};

function App({ setRooms, setServices, setPackages }) {
  useEffect(() => {
    getMuineRooms().then((items) => {
      if (!items) {
        return;
      }
      let result = [];
      items.docs.forEach((doc) => {
        result.push(doc.data());
      });
      result = result.sort((item1, item2) => item1.priority - item2.priority);
      setRooms(result);
    });

    getMuineServices().then((items) => {
      if (!items) {
        return;
      }
      let result = [];
      items.docs.forEach((doc) => {
        result.push(doc.data());
      });
      result = result.sort((item1, item2) => item1.priority - item2.priority);
      setServices(result);
    });

    getPackages().then((items) => {
      if (!items) {
        return;
      }
      let result = [];
      items.docs.forEach((doc) => {
        result.push(doc.data());
      });
      result = result.sort((item1, item2) => item1.priority - item2.priority);
      setPackages(result);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <ScrollToTop>
        <Router>
          <Menu />
          <Switch>
            <Route path={changePath("/")} exact component={Home} />
            <Route path={changePath("/rooms/:id")} component={RoomDetailPage} />
            <Route path={changePath("/rooms")} component={RoomsPage} />
            <Route path={changePath("/gallery")} component={GalleryPage} />
            <Route path={changePath("/services")} component={ServicesPage} />
            <Route path={changePath("/contact")} component={ContactPage} />
            <Route path={changePath("/about")} component={AboutPage} />
            <Route path={changePath("/packages")} component={PackagesPage} />
            <Route
              path={changePath("/packageDetail/:id")}
              component={PackageDetailPage}
            />
            <Route
              path={changePath("/serviceDetail/:id")}
              component={ServiceDetailPage}
            />
          </Switch>
        </Router>
        <Footer />
      </ScrollToTop>
      {/* <FacebookMessager /> */}
    </div>
  );
}

export default connect(null, (dispatch) => ({
  setRooms: (data) => dispatch(setRoomsAction(data)),
  setServices: (data) => dispatch(SetServicesAction(data)),
  setPackages: (data) => dispatch(setPackagesAction(data)),
}))(App);
