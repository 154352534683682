import React from "react";
import { connect } from "react-redux";
import InfoContainer from "./InfoContainer";
import LinksContainer from "./LinksContainer";
import FollowContainer from "./FollowContainer";
import BottomContainer from "./BottomContainer";
import Localization from "../Localization/Localize";

const Footer = () => {
  return (
    <div id="footer-wrapper">
      <div className="container">
        <InfoContainer
          title={Localization.localize("aboutTitle")}
          description={Localization.localize("contactDescription")}
          resortAddress={Localization.localize("resortAddress")}
          representativeAddress={Localization.localize("representativeAddress")}
        />
        <LinksContainer
          home={Localization.localize("menuHome")}
          rooms={Localization.localize("menuRoom")}
          services={Localization.localize("menuService")}
          packages={Localization.localize("menuPackage")}
          gallery={Localization.localize("menuGallery")}
          about={Localization.localize("menuAbout")}
          contact={Localization.localize("menuContact")}
          usefulLink={Localization.localize("usefulLink")}
        />
        <FollowContainer
          contactText={Localization.localize("contactTitle")}
          follow={Localization.localize("followTitle")}
        />
        <div className="clear" />
      </div>
      <BottomContainer />
    </div>
  );
};

export default connect(state => {
  return {
    lang: state.lang.currentLang
  };
})(Footer);
