const initState = {
  list: [],
  serviceDetail: {}
};
function muineServiceReducer(state = initState, action) {
  if (action.type === "SET_SERVICES") {
    return {
      ...state,
      list: action.data
    };
  }
  if (action.type === "SET_SERVICE_DETAIL") {
    return {
      ...state,
      serviceDetail: action.data
    };
  }

  return state;
}
export default muineServiceReducer;
