import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setPackageDetail as setPackageDetailAction } from "../../redux/actions/package";
import Localization from "../Localization/Localize";

const PackagesContainer = ({
  isLast,
  currentPackage,
  idx,
  setPackageDetail,
  lang,
}) => {
  const setDetail = (data) => {
    setPackageDetail(data);
  };
  return (
    <>
      <div
        key={idx}
        className={isLast ? "blog-post-repeat lastcols" : "blog-post-repeat"}
      >
        <article
          id="post-101"
          className="post-101 post type-post status-publish format-standard"
        >
          <div className="post-thumb">
            <Link
              className="room-title yellow-text"
              to={`/packageDetail/${currentPackage.id}`}
              onClick={() => setDetail(currentPackage.id)}
            >
              <img
                alt=" "
                src={currentPackage.urls && currentPackage.urls[0]}
              />
              <div className="post-overlay"></div>
            </Link>
          </div>
          <div className="post-data">
            <header className="entry-header">
              <h3 className="post-title">
                <Link
                  className="room-title yellow-text"
                  to={`/packageDetail/${currentPackage.id}`}
                  onClick={() => setDetail(currentPackage.id)}
                >
                  {currentPackage.name[lang]}
                </Link>
              </h3>
            </header>

            <div className="entry-summary">
              <p>{currentPackage.description[lang]}</p>
            </div>
            <div className="post_bottom">
              <div className="post_bottom_left">
                <div className="postmeta">
                  <Link
                    className="room-title yellow-text"
                    to={`/packageDetail/${currentPackage.id}`}
                    onClick={() => setDetail(currentPackage.id)}
                  >
                    {Localization.localize("readMoreText")}
                  </Link>
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>{" "}
          <div className="clear"></div>
        </article>
      </div>
    </>
  );
};

export default connect(
  (state) => {
    return {
      package: state.muinePackage.packageDetail,
      packages: state.muinePackage.list,
      lang: state.lang.currentLang,
    };
  },
  (dispatch) => ({
    setPackageDetail: (data) => dispatch(setPackageDetailAction(data)),
  })
)(PackagesContainer);
