import React from "react";

const ContenContainer = ({
  title,
  description,
  addressTitle,
  addressOffice,
  representativeAddress,
  phoneTitle,
  resortAddress,
}) => {
  return (
    <>
      <div className="one_half contact-page-info">
        <h2>{title}</h2>
        <p> {description} </p>
        <address>
          <strong>{addressTitle}: </strong>
          <p> {resortAddress}</p>
        </address>

        <address>
          <strong>{addressOffice}: </strong>
          <p> {representativeAddress}</p>
        </address>
        <address>
          <strong>Email: </strong>
          <p>
            <a
              href="mailto:info@muinebayresort.com"
              style={{ color: "#ce9b51" }}
            >
              info@muinebayresort.com
            </a>
            <br />
            <a
              href="mailto:saleadmin@muinebayresort.com"
              style={{ color: "#ce9b51" }}
            >
              saleadmin@muinebayresort.com
            </a>
          </p>
        </address>
        <address>
          <strong>{phoneTitle}: </strong>
          <p>
            <a href="tel:+84 252 222 0222" style={{ color: "#ce9b51" }}>
              Resort: +84 252 222 0222
            </a>
            <br />
            <a href="tel:+84 28 3920 9848" style={{ color: "#ce9b51" }}>
              Office: +84 28 3920 9848
            </a>
          </p>
        </address>

        <address>
          <strong>Fax : </strong>
          <p> +84.252 222 0272</p>
        </address>
        <div className="contact-social">
          <div className="social-icons">
            <a
              style={{ backgroundColor: "" }}
              href="https://www.facebook.com/MuineBay.Resort.PhanThiet"
              target="_blank"
              rel="noopener noreferrer"
              title="facebook"
            >
              <i className="fab fa-facebook" />
            </a>

            <a
              style={{ backgroundColor: "" }}
              href="https://www.instagram.com/muinebay_resort"
              rel="noopener noreferrer"
              target="_blank"
              title="instagram"
            >
              <i className="fab fa-instagram" />
            </a>

            <a
              style={{ backgroundColor: "" }}
              href="https://www.tripadvisor.com/Hotel_Review-g1009804-d2075996-Reviews-Muine_Bay_Resort-Mui_Ne_Phan_Thiet_Binh_Thuan_Province.html"
              target="_blank"
              rel="noopener noreferrer"
              title="tripadvisor"
            >
              <i className="fab fa-tripadvisor"></i>
            </a>
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default ContenContainer;
