import React from "react";

const LinksContainer = ({
  home,
  rooms,
  services,
  packages,
  gallery,
  about,
  contact,
  usefulLink,
}) => {
  return (
    <>
      <div className="cols-3 widget-column usefullLink">
        <h5>{usefulLink}</h5>
        <div className="menu-footer-menu-container">
          <ul id="menu-footer-menu" className="menu">
            <li
              id="menu-item-131"
              className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-home menu-item-131"
            >
              <a href="/">{home}</a>
            </li>

            <li
              id="menu-item-135"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-135"
            >
              <a href="/rooms">{rooms}</a>
            </li>
            <li
              id="menu-item-135"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-135"
            >
              <a href="/services">{services}</a>
            </li>
            <li
              id="menu-item-135"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-135"
            >
              <a href="/packages">{packages}</a>
            </li>
            <li
              id="menu-item-134"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-134"
            >
              <a href="/gallery">{gallery}</a>
            </li>
            <li
              id="menu-item-132"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-132"
            >
              <a href="/about">{about}</a>
            </li>
            <li
              id="menu-item-133"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-133"
            >
              <a href="/contact">{contact}</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default LinksContainer;
