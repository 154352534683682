import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { setServiceDetail as setServiceDetailAction } from "../../redux/actions/service";

const HotServiceContainer = ({
  hotItems,
  setServiceDetail,
  lang,
  location
}) => {
  const setDetail = data => {
    setServiceDetail(data);
  };
  const currentPath = useMemo(() => location.pathname, [location.pathname]);
  const validPath = currentPath.split("/").pop() ?? "";

  return (
    <>
      <aside id="recent-posts-2" className="widget widget_recent_entries">
        <div className="widget_content">
          {" "}
          <h3 className="widget-title">
            <span>Hot Services</span>
          </h3>{" "}
          <ul>
            {hotItems &&
              hotItems.map((hotItem, index) => {
                const isActive = validPath === hotItem.id;
                return (
                  <li key={index}>
                    <Link
                      to={`/serviceDetail/${hotItem.id}`}
                      onClick={() => setDetail(hotItem.id)}
                      style={isActive ? { color: "#ce9b51" } : {}}
                    >
                      {hotItem.name && hotItem.name[lang]}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default connect(
  state => ({
    lang: state.lang.currentLang
  }),
  dispatch => ({
    setServiceDetail: data => dispatch(setServiceDetailAction(data))
  })
)(withRouter(HotServiceContainer));
