import React from "react";
import { connect } from "react-redux";
import Localization from "../Localization/Localize";

const ThreeCollumnsContainer = ({ title, photos }) => {
  if (!photos) {
    return null;
  }
  return (
    <>
      <div className="custom-heading" style={{ textAlign: "left" }}>
        <h2 style={{ color: "#242c3a" }}>
          <span>{title}</span>
          {Localization.localize("exploreGallerySubtile")}
        </h2>
      </div>

      {photos &&
        photos.map((photoUrl, index) => {
          const isLast = index !== 0 && (index + 1) % 3 === 0;
          const classNameGallery = isLast
            ? "gallery gal-col-three lastcols"
            : "gallery gal-col-three";

          return (
            <div key={index} className={classNameGallery}>
              <div index={index} key={index} className="gallery-image">
                <img alt=" " src={photoUrl} />
                <div className="gallery-icon">
                  <a
                    href={photoUrl}
                    data-fancybox="images"
                    className="yellow-text"
                  >
                    {" "}
                  </a>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default connect(state => {
  return {
    lang: state.lang.currentLang
  };
})(ThreeCollumnsContainer);
