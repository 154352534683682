import React, { useMemo } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Item from "./Item";
import TopBar from "./TopBar";
import logo from "../../images/common/logo.png";

import Localization from "../Localization/Localize";

const Menu = ({ location }) => {
  const currentPath = useMemo(() => location.pathname, [location.pathname]);

  const menus = [
    {
      pathname: "/",
      name: Localization.localize("menuHome"),
    },
    {
      pathname: "/rooms",
      name: Localization.localize("menuRoom"),
    },
    {
      pathname: "/services",
      name: Localization.localize("menuService"),
    },
    {
      pathname: "/packages",
      name: Localization.localize("menuPackage"),
    },
    {
      pathname: "/gallery",
      name: Localization.localize("menuGallery"),
    },
    {
      pathname: "/about",
      name: Localization.localize("menuAbout"),
    },
    {
      pathname: "/contact",
      name: Localization.localize("menuContact"),
    },
  ];

  return (
    <div className="menu-bar">
      <div className="top-header">
        <div className="container">
          <TopBar />
          <div className="clear" />
        </div>
      </div>

      <header className="header">
        <div className="container mob-padding">
          <div className="logo">
            <Link to="/">
              {" "}
              <img src={logo} alt="Muine Bay" />
            </Link>
            <p> {Localization.localize("menuSubSlogan")}</p>
          </div>

          <div className="toggle">
            <a className="toggleMenu" href="#muine">
              {" "}
            </a>
          </div>

          <div className="head-button">
            <a
              href="https://be.synxis.com/?adult=1&arrive=2020-12-11&chain=9005&child=0&config=mbrsphth&currency=VND&depart=2020-12-12&fbclid=IwAR3tznENRzIDQLBfpYpb86dHYQnyDyCBdmbFiNIx2MLOYM7ZBPgRS4UiMBg&hotel=60715&level=hotel&locale=en-US&rooms=1&theme=mbrsphth"
              target="_blank"
              rel="noopener noreferrer"
              className="hover-link"
            >
              {Localization.localize("bookNow")}
            </a>
          </div>

          <div className="sitenav">
            <div className="menu-main-menu-container">
              <ul id="menu-main-menu" className="menu">
                {menus.map((menu) => (
                  <Item
                    {...menu}
                    key={menu.pathname}
                    currentPath={currentPath}
                  />
                ))}
              </ul>
            </div>
          </div>
          <div className="clear" />
        </div>
      </header>
    </div>
  );
};

export default connect((state) => {
  return {
    lang: state.lang.currentLang,
  };
})(withRouter(Menu));
