import React, { useEffect, useState } from "react";
import { getAbout } from "../../DataManager/config";
import { connect } from "react-redux";
import Banner from "../Common/Banner";
import CounterUpContainer from "./CounterUpContainer";
import WhyContainer from "./WhyContainer";
import WelcomeContainer from "./WelcomeContainer";

const AboutPage = ({ lang }) => {
  const [about, setAbout] = useState("MuineBay");

  useEffect(() => {
    getAbout().then(item => {
      if (!item) {
        return;
      }
      const data = item.data();
      setAbout(data);
    });
  }, []);

  return (
    <div>
      <Banner name={about && about.page_title && about.page_title[lang]} />

      <div className="container content-area">
        <div className="middle-align">
          <div className="site-main sitefull">
            <article
              id="post-57"
              className="post-57 page type-page status-publish"
            >
              <div className="entry-content">
                <WelcomeContainer
                  welcomeTitle={
                    about && about.welcome_title && about.welcome_title[lang]
                  }
                  welcomeSubtitle={
                    about &&
                    about.welcome_subtitle &&
                    about.welcome_subtitle[lang]
                  }
                  aboutTitle={about && about.title && about.title[lang]}
                  visionTitle={
                    about && about.vision_title && about.vision_title[lang]
                  }
                  vision={about && about.vision && about.vision[lang]}
                  missionTitle={
                    about && about.mission_title && about.mission_title[lang]
                  }
                  mission={about && about.mission && about.mission[lang]}
                />
                <div className="clear"></div>
                <WhyContainer
                  section={
                    about && about.why_section && about.why_section[lang]
                  }
                  sectionTitle={
                    about &&
                    about.why_section_title &&
                    about.why_section_title[lang]
                  }
                  firstWhy={about && about.first_why && about.first_why[lang]}
                  firstReason={
                    about && about.first_reason && about.first_reason[lang]
                  }
                  secondWhy={
                    about && about.second_why && about.second_why[lang]
                  }
                  secondReason={
                    about && about.second_reason && about.second_reason[lang]
                  }
                  thirdWhy={about && about.third_why && about.third_why[lang]}
                  thirdReason={
                    about && about.third_reason && about.third_reason[lang]
                  }
                />
                <div className="clear"></div>
                <CounterUpContainer
                  roomNum={about && about.room_num}
                  roomTitle={
                    about && about.room_title && about.room_title[lang]
                  }
                  happyNum={about && about.happy_customer_num}
                  happyTitle={
                    about &&
                    about.happy_customer_title &&
                    about.happy_customer_title[lang]
                  }
                  awardNum={about && about.awards_num}
                  awardTitle={
                    about && about.award_title && about.award_title[lang]
                  }
                  experiencesNum={about && about.years_experience_num}
                  experiencesTitle={
                    about &&
                    about.years_experience_title &&
                    about.years_experience_title[lang]
                  }
                />
                <div className="clear"></div>
              </div>
            </article>{" "}
          </div>
          <div className="clear"></div>
        </div>
      </div>
    </div>
  );
};

export default connect(state => {
  return {
    lang: state.lang.currentLang
  };
})(AboutPage);
