import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { setPackageDetail as setPackageDetailAction } from "../../redux/actions/package";

const HotPackagesContainer = ({
  hotItems,
  setPackageDetail,
  lang,
  location
}) => {
  const setDetail = data => {
    setPackageDetail(data);
  };

  const currentPath = useMemo(() => location.pathname, [location.pathname]);
  const validPath = currentPath.split("/").pop() ?? "";

  return (
    <>
      <aside id="recent-posts-2" className="widget widget_recent_entries">
        <div className="widget_content">
          {" "}
          <h3 className="widget-title">
            <span>Hot Packages</span>
          </h3>{" "}
          <ul>
            {hotItems &&
              hotItems.map((hotItem, index) => {
                if (!hotItem.isActive) {
                  return null;
                }
                const isActive = validPath === hotItem.id;
                return (
                  <li key={index} className="uppercaseTitle">
                    <Link
                      to={`/packageDetail/${hotItem.id}`}
                      onClick={() => setDetail(hotItem.id)}
                      style={isActive ? { color: "#ce9b51" } : {}}
                    >
                      {hotItem.name && hotItem.name[lang]}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default connect(
  state => ({
    lang: state.lang.currentLang
  }),
  dispatch => ({
    setPackageDetail: data => dispatch(setPackageDetailAction(data))
  })
)(withRouter(HotPackagesContainer));
