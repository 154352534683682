import React from "react";
import { connect } from "react-redux";
import Localization from "../Localization/Localize";
import awardImg from "../../images/common/muineTripadvisor2020.jpg";
import agodaAwardImg from "../../images/common/muineAgoda.jpg";

const AwardsContainer = ({ lang }) => {
  return (
    <>
      <aside id="media_image-2" className="widget widget_media_image">
        <div className="widget_content">
          <h3 className="widget-title">
            <span>{Localization.localize("awardsTitle")}</span>
          </h3>
          <a
            href="https://www.tripadvisor.com/Hotel_Review-g1009804-d2075996-Reviews-Muine_Bay_Resort-Mui_Ne_Phan_Thiet_Binh_Thuan_Province.htm"
            target="_blank"
            rel="noopener noreferrer"
            title="tripadvisor"
          >
            <img
              style={{ width: "400px", height: "300px" }}
              src={awardImg}
              className="image wp-image-166  attachment-full size-full"
              alt=""
            ></img>
          </a>
          <br />
          <a
            href="https://www.agoda.com/muine-bay-resort/hotel/phan-thiet-vn.html?fbclid=IwAR2Le8yXgYwk5fhggwidr9JAkwD7XSWSJEnzBbkLjma3uUKJepUaPy4Yko0"
            target="_blank"
            rel="noopener noreferrer"
            title="Agoda"
          >
            <img
              width="800"
              height="757"
              src={agodaAwardImg}
              className="image wp-image-166  attachment-full size-full"
              alt=""
            ></img>
          </a>
        </div>
      </aside>
    </>
  );
};

export default connect((state) => {
  return {
    lang: state.lang.currentLang,
  };
})(AwardsContainer);
