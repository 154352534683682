import React from "react";
import { connect } from "react-redux";
import { setRoomDetail as setRoomDetailAction } from "../../redux/actions/room";
import { Link } from "react-router-dom";
import Banner from "../Common/Banner";
import Localization from "../Localization/Localize";

const RoomsPage = ({ lang, rooms, setRoomDetail }) => {
  const setDetail = (data) => {
    setRoomDetail(data);
  };

  return (
    <div>
      <Banner name={Localization.localize("roomBannerTitle")} />
      <div className="container content-area">
        <div className="middle-align">
          <div className="site-main sitefull">
            <article
              id="post-128"
              className="post-128 page type-page status-publish"
            >
              <div className="entry-content">
                {rooms &&
                  rooms.map((room, index) => {
                    if (!room.isActive) {
                      return null;
                    }
                    const isLast = index !== 0 && (index + 1) % 3 === 0;
                    return (
                      <div
                        key={index}
                        className={
                          isLast ? "one_third last_column" : "one_third"
                        }
                      >
                        <div className="muine-room">
                          <div className="muine-room-thumb">
                            <img
                              alt="MUINE BAY"
                              src={room && room.urls && room.urls[0]}
                            />
                          </div>
                          <div className="muine-room-cont">
                            <div className="muine-room-cont-wrap">
                              <Link
                                to={`/rooms/${room.id}`}
                                onClick={() => setDetail(room.id)}
                              >
                                <h3 className="yellow-text">
                                  {room.name && room.name[lang]}
                                </h3>
                              </Link>
                              <p>
                                {" "}
                                {room.short_description &&
                                  room.short_description[lang]}{" "}
                              </p>
                              <div className="muine-room-price">
                                {room.price && room.price[lang]}
                                <span className="price-title">
                                  {Localization.localize("priceSubtitle")}
                                </span>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="clear" />
                      </div>
                    );
                  })}

                <div className="clear" />
              </div>
            </article>{" "}
          </div>
          <div className="clear" />
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state) => {
    return {
      lang: state.lang.currentLang,
      rooms: state.room.list,
    };
  },
  (dispatch) => ({
    setRoomDetail: (data) => dispatch(setRoomDetailAction(data)),
  })
)(RoomsPage);
