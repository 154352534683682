import React from "react";

const InfoContainer = ({
  title,
  description,
  resortAddress,
  representativeAddress,
}) => {
  return (
    <>
      <div className="cols-3 widget-column">
        <h5>{title}</h5>
        <div className="textwidget">
          <p>{description} </p>
          <div className="contactdetail">
            <p>
              <i className="fa fa-map-marker" />
              {resortAddress}
              <br></br>
              <br></br>
              <i className="fa fa-map-marker" />
              {representativeAddress}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoContainer;
