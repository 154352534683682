import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setServiceDetail as setServiceDetailAction } from "../../redux/actions/service";
import Localization from "../Localization/Localize";

const Services = ({ lang, services, setServiceDetail }) => {
  const setDetail = (data) => {
    setServiceDetail(data);
  };

  return (
    <section className="no-padding">
      <div className="full-container">
        <div
          className="wow fadeInUp"
          data-wow-duration="1s"
          style={{
            visibility: "visible",
            animationDuration: "1s",
            animationName: "fadeInUp",
          }}
        >
          {services &&
            services.map((service, index) => {
              const isLast = index === 3;
              if (index > 3) {
                return null;
              }
              return (
                <div
                  key={index}
                  className={isLast ? "one_fourth last_column" : "one_fourth"}
                >
                  <div className="feature-box">
                    <div className="feature-box-thumb">
                      <img alt=" " src={service.home_banner} />
                      <div className="featture-box-cont">
                        <h2>{service.name[lang]}</h2>
                        <p> {service.short_description[lang]} </p>
                        <Link
                          style={{ color: "#CE9B51" }}
                          to={`/serviceDetail/${service.id}`}
                          onClick={() => setDetail(service.id)}
                        >
                          {Localization.localize("readMoreText")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          <div className="clear" />
        </div>
      </div>
    </section>
  );
};

export default connect(
  (state) => {
    return {
      service: state.muineService.serviceDetail,
      services: state.muineService.list,
      lang: state.lang.currentLang,
    };
  },
  (dispatch) => ({
    setServiceDetail: (data) => dispatch(setServiceDetailAction(data)),
  })
)(Services);
