import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { setRoomDetail as setRoomDetailAction } from "../../redux/actions/room";

const HotRoomContainer = ({ hotRooms, setRoomDetail, lang, location }) => {
  const setDetail = data => {
    setRoomDetail(data);
  };
  const currentPath = useMemo(() => location.pathname, [location.pathname]);
  const validPath = currentPath.split("/").pop() ?? "superior";

  return (
    <>
      <aside id="recent-posts-2" className="widget widget_recent_entries">
        <div className="widget_content">
          {" "}
          <h3 className="widget-title">
            <span>Muine Bay Rooms</span>
          </h3>{" "}
          <ul>
            {hotRooms &&
              hotRooms.map((hotRoom, index) => {
                const isActive = validPath === hotRoom.id;
                return (
                  <li key={index}>
                    <Link
                      to={`/rooms/${hotRoom.id}`}
                      disabled={isActive}
                      onClick={() => setDetail(hotRoom.id)}
                      style={isActive ? { color: "#ce9b51" } : {}}
                    >
                      {hotRoom.name && hotRoom.name[lang]}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default connect(
  state => ({
    lang: state.lang.currentLang
  }),
  dispatch => ({
    setRoomDetail: data => dispatch(setRoomDetailAction(data))
  })
)(withRouter(HotRoomContainer));
