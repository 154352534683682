import React from "react";

const WhyContainer = ({
  section,
  sectionTitle,
  firstWhy,
  firstReason,
  secondWhy,
  secondReason,
  thirdWhy,
  thirdReason
}) => {
  return (
    <>
      <div style={{ height: "50px" }}></div>
      <div className="custom-heading" style={{ textAlign: "center" }}>
        <h2 style={{ color: "#242c3a" }}>
          <span>{section}</span>
          {sectionTitle}
        </h2>
      </div>
      <div className="one_third ">
        <div className="icon-info-box">
          <div className="info-box-wrap">
            <div className="icon-top">
              <i className="far fa-compass" aria-hidden="true"></i>
            </div>
            <h3 className="yellow-text">{firstWhy}</h3>
            <p>{firstReason} </p>
          </div>
        </div>
      </div>
      <div className="one_third ">
        <div className="icon-info-box">
          <div className="info-box-wrap">
            <div className="icon-top">
              <i className="fa fa-life-ring" aria-hidden="true"></i>
            </div>
            <h3 className="yellow-text">{secondWhy}</h3>
            <p>{secondReason} </p>
          </div>
        </div>
      </div>
      <div className="one_third  last_column">
        <div className="icon-info-box">
          <div className="info-box-wrap">
            <div className="icon-top">
              <i className="fas fa-expand-arrows-alt" aria-hidden="true"></i>
            </div>
            <h3 className="yellow-text">{thirdWhy}</h3>
            <p>{thirdReason} </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyContainer;
