import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setRoomDetail as setRoomDetailAction } from "../../redux/actions/room";
import AwardsContainer from "../Rooms/AwardsContainer";
import Localization from "../Localization/Localize";

const HotDealContainer = ({ detailLink, lang, hotDealRoom, setRoomDetail }) => {
  const setDetail = (data) => {
    setRoomDetail(data);
  };
  return (
    <>
      <div className="widget_content">
        <h3 className="widget-title">
          <span>{Localization.localize("hotDealsTitle")}</span>
        </h3>
        <div>
          <div>
            <div>
              <div className="room-grid">
                <div className="room-top-wrap">
                  <div>
                    <Link
                      className="room-title yellow-text"
                      to="/rooms/ocean_panorama"
                      onClick={() => setDetail(detailLink)}
                    >
                      <img
                        width="1000"
                        height="600"
                        src={
                          hotDealRoom && hotDealRoom.urls && hotDealRoom.urls[0]
                        }
                        alt=""
                        sizes="(max-width: 1000px) 100vw, 1000px"
                      />{" "}
                    </Link>
                  </div>
                  <Link
                    className="muine-modal-button hover-link"
                    to="/rooms/ocean_panorama"
                    onClick={() => setDetail(detailLink)}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </Link>
                </div>
                <div className="room-main-wrap">
                  <h3>
                    <Link
                      className="room-title yellow-text"
                      to="/rooms/ocean_panorama"
                      onClick={() => setDetail(detailLink)}
                    >
                      {hotDealRoom &&
                        hotDealRoom.name &&
                        hotDealRoom.name[lang]}
                    </Link>
                  </h3>
                  <div className="room-meta-info">
                    <div className="meta-content">
                      <ul className="muine-room-meta">
                        <li className="meta-bed">
                          <span className="meta-label">
                            {Localization.localize("bedTitle")}
                          </span>
                          <span className="meta-text">
                            {" "}
                            {hotDealRoom &&
                              hotDealRoom.bedType &&
                              hotDealRoom.bedType[lang]}
                          </span>
                        </li>

                        <li className="meta-adults">
                          <span className="meta-label">
                            {Localization.localize("peopleTitle")}
                          </span>
                          <span className="meta-text">2</span>
                        </li>

                        <li className="meta-view">
                          <span className="meta-label">
                            {Localization.localize("viewTitle")}
                          </span>
                          <span className="meta-text">Ocean</span>
                        </li>

                        <li className="meta-size">
                          <span className="meta-label">
                            {Localization.localize("roomSizeTitle")}
                          </span>
                          <span className="meta-text">41 Sqm</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <footer>
                  <div className="muine-price muine-room-price">
                    <span className="price-value">
                      <b
                        data-thousand=","
                        data-decimal="."
                        data-numberdecimal="1"
                        data-format="0,0.0"
                        data-price="100"
                      >
                        {hotDealRoom &&
                          hotDealRoom.price &&
                          hotDealRoom.price[lang]}
                      </b>
                    </span>
                    <span className="price-title">
                      {Localization.localize("priceSubtitle")}
                    </span>{" "}
                  </div>
                </footer>
              </div>
              <AwardsContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => {
    return {
      room: state.room.roomDetail,
      rooms: state.room.list,
      lang: state.lang.currentLang,
    };
  },
  (dispatch) => ({
    setRoomDetail: (data) => dispatch(setRoomDetailAction(data)),
  })
)(HotDealContainer);
