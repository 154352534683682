import React from "react";
import CountUp from "react-countup";

const CounterUpContainer = ({
  roomNum,
  roomTitle,
  happyNum,
  happyTitle,
  awardNum,
  awardTitle,
  experiencesNum,
  experiencesTitle
}) => {
  return (
    <>
      <div style={{ height: "50px" }}></div>
      <div className="counter-box" id="" style={{ borderColor: "#242c3a" }}>
        <div className="counter-bdr">
          <div className="inner-counter">
            <h3 className="counter" style={{ color: "#242c3a" }}>
              {roomNum && (
                <CountUp
                  end={roomNum}
                  duration={8}
                  separator=","
                  redraw={true}
                  className="counter"
                  style={{
                    color: "#242c3a",
                    font: "700 46px Assistant"
                  }}
                />
              )}
            </h3>
            <span style={{ color: "#242c3a" }}>+</span>
            <h3 className="counter-ttl" style={{ color: "#242c3a" }}>
              {roomTitle}
            </h3>
          </div>
        </div>
      </div>
      <div className="counter-box" id="" style={{ borderColor: "#242c3a" }}>
        <div className="counter-bdr">
          <div className="inner-counter">
            <h3 className="counter" style={{ color: "#242c3a" }}>
              {happyNum && (
                <CountUp
                  end={happyNum}
                  duration={8.0}
                  separator=","
                  redraw={true}
                  className="counter"
                  style={{
                    color: "#242c3a",
                    font: "700 46px Assistant"
                  }}
                />
              )}
            </h3>
            <span style={{ color: "#242c3a" }}>+</span>
            <h3 className="counter-ttl" style={{ color: "#242c3a" }}>
              {" "}
              {happyTitle}
            </h3>
          </div>
        </div>
      </div>
      <div className="counter-box" id="" style={{ borderColor: "#242c3a" }}>
        <div className="counter-bdr">
          <div className="inner-counter">
            <h3 className="counter" style={{ color: "#242c3a" }}>
              {awardNum && (
                <CountUp
                  end={awardNum}
                  duration={10}
                  separator=","
                  redraw={true}
                  className="counter"
                  style={{
                    color: "#242c3a",
                    font: "700 46px Assistant"
                  }}
                />
              )}
            </h3>
            <span style={{ color: "#242c3a" }}>+</span>
            <h3 className="counter-ttl" style={{ color: "#242c3a" }}>
              {awardTitle}
            </h3>
          </div>
        </div>
      </div>
      <div className="counter-box" id="last" style={{ borderColor: "#242c3a" }}>
        <div className="counter-bdr">
          <div className="inner-counter">
            <h3 className="counter" style={{ color: "#242c3a" }}>
              {experiencesNum && (
                <CountUp
                  end={experiencesNum}
                  duration={10}
                  separator=","
                  redraw={true}
                  className="counter"
                  style={{
                    color: "#242c3a",
                    font: "700 46px Assistant"
                  }}
                />
              )}
            </h3>
            <span style={{ color: "#242c3a" }}>+</span>
            <h3 className="counter-ttl" style={{ color: "#242c3a" }}>
              {experiencesTitle}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default CounterUpContainer;
