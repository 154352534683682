const initState = {
  list: [],
  packageDetail: {}
};
function muinePackageReducer(state = initState, action) {
  if (action.type === "SET_PACKAGES") {
    return {
      ...state,
      list: action.data
    };
  }
  if (action.type === "SET_PACKAGE_DETAIL") {
    return {
      ...state,
      packageDetail: action.data
    };
  }

  return state;
}
export default muinePackageReducer;
