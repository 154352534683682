import React, { useCallback } from "react";
import { connect } from "react-redux";
import { changeLanguage } from "../../redux/actions/lang";

const FollowContainer = ({ dispatch, contactText, follow, lang }) => {
  const handleChangeLanguage = useCallback(() => {
    if (lang === "vn") {
      return dispatch(changeLanguage("en"));
    }
    return dispatch(changeLanguage("vn"));
  }, [dispatch, lang]);
  return (
    <>
      <div className="cols-3 widget-column">
        <h5>{contactText}</h5>
        <div className="">
          <p>
            <i className="fa fa-envelope" />
            <a
              href="mailto:info@muinebayresort.com"
              style={{ color: "#ce9b51", marginLeft: "6px" }}
            >
              info@muinebayresort.com
            </a>
          </p>

          <p>
            <i className="fa fa-phone" />
            <a
              href="tel:+84 252 222 0222"
              style={{ color: "#ce9b51", marginLeft: "6px" }}
            >
              Resort: +84 252 222 0222
            </a>
          </p>

          <p>
            <i className="fa fa-phone" />
            <a
              href="tel:+84 28 3920 9848"
              style={{ color: "#ce9b51", marginLeft: "6px" }}
            >
              Office: +84 28 3920 9848
            </a>
          </p>
        </div>
        <div
          className="social-icons"
          style={{ display: "flex", alignItems: "center" }}
        >
          <h5 style={{ marginBottom: "0" }}>{follow}</h5>

          <a
            style={{ backgroundColor: "" }}
            href="https://www.facebook.com/MuineBay.Resort.PhanThiet"
            target="_blank"
            rel="noopener noreferrer"
            title="facebook"
          >
            <i className="fab fa-facebook-f"></i>
          </a>

          <a
            style={{ backgroundColor: "" }}
            href="https://www.instagram.com/muinebay_resort"
            target="_blank"
            rel="noopener noreferrer"
            title="instagram"
          >
            <i className="fab fa-instagram"></i>
          </a>

          <a
            style={{ backgroundColor: "" }}
            href="https://www.youtube.com/channel/UCaYQuZi2NxNJd6P34eAUe2w?view_as=subscriber"
            target="_blank"
            rel="noopener noreferrer"
            title="youtube"
          >
            <i className="fab fa-youtube"></i>
          </a>
          <a
            style={{ backgroundColor: "" }}
            href="https://www.tripadvisor.com/Hotel_Review-g1009804-d2075996-Reviews-Muine_Bay_Resort-Mui_Ne_Phan_Thiet_Binh_Thuan_Province.html"
            target="_blank"
            rel="noopener noreferrer"
            title="tripadvisor"
          >
            <i className="fab fa-tripadvisor"></i>
          </a>
          <div className="divideSpacer">|</div>
          <a
            className="languageButton"
            href="#muine"
            title="language"
            onClick={handleChangeLanguage}
          >
            <i
              className="fas fa-language fa"
              style={{
                fontSize: "1.6em",
                transform: "translate(5px, 4px)",
              }}
            />
          </a>
        </div>{" "}
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    lang: state.lang.currentLang,
  };
})(FollowContainer);
