import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Localization from "../Localization/Localize";

const Banner = ({ name }) => {
  return (
    <div
      className="innerbanner"
      style={{
        backgroundSize: "cover",
        top: "52px"
      }}
    >
      <div className="container">
        <h1 className="innerpage-title">
          <span>{name}</span>
        </h1>
        <div id="crumbs">
          <Link to="/">{Localization.localize("homeLinkTitle")}</Link> »{" "}
          <span className="current">{name}</span>
        </div>
      </div>
    </div>
  );
};

export default connect(state => {
  return {
    lang: state.lang.currentLang
  };
})(Banner);
