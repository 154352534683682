import React from "react";
import { connect } from "react-redux";
import Localization from "../Localization/Localize";

const OptionalPackagesContainer = ({ selectedRoom, lang }) => {
  return (
    <>
      <div className="room-box">
        <div className="room-optional-packages">
          <h4>{Localization.localize("additionalOffer")}</h4>
          <div className="inner">
            <div className="muine-room-packages">
              {selectedRoom.offersContent &&
                selectedRoom.offersContent.map((offer, index) => {
                  return (
                    <div className="package-item  has-content" key={index}>
                      <div className="package-content">
                        <h4>{offer.title[lang]}</h4>

                        <div className="muine-package-price">
                          <span className="price-value">
                            <b>{offer.subtitle[lang]}</b>{" "}
                          </span>
                        </div>
                      </div>
                      <div
                        className="package-description"
                        style={{ display: "none" }}
                      >
                        <p>{offer.description[lang]}</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="room-discounts-info">
          <div className="alert alert-success">
            <h4>{Localization.localize("discountTitle")}</h4>
            <div className="content">
              <ul>
                <li>
                  {" "}
                  <i className="fa fa-check" aria-hidden="true"></i>
                  {Localization.localize("pageTitle")}{" "}
                  <a href="tel:+84 28 3920 9848" style={{ color: "#ce9b51" }}>
                    +84 28 3920 9848
                  </a>{" "}
                  or{" "}
                  <span>
                    <a
                      style={{ color: "#ce9b51" }}
                      href="https://www.facebook.com/MuineBay.Resort.PhanThiet"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="facebook"
                    >
                      Facebook
                    </a>
                  </span>{" "}
                  {Localization.localize("discountDescription")}
                </li>
                <li>
                  {" "}
                  <i className="fa fa-check" aria-hidden="true"></i>
                  {selectedRoom.discount && selectedRoom.discount[lang]}{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <div className="alert alert-danger">
            <h4>{Localization.localize("childPolicyTitle")}</h4>

            <div className="content">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    selectedRoom.childPolicy && selectedRoom.childPolicy[lang],
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((state) => {
  return {
    lang: state.lang.currentLang,
  };
})(OptionalPackagesContainer);
