export default {
  galleryTitle: "OUR GALLERY",
  homeLinkTitle: "Home",

  exploreGallerySubtile: "Photos",

  packageTitle: "PACKAGES",

  priceSubtitle: "From/Per Night",

  priceSubtitleV2: "from / per night",

  discountDescription: "to get discount.",

  secondDiscountDescription: "Stay 3 days - 5%/room",

  roomPhoto: "Explore Muine",

  roomBannerTitle: "OUR ROOMS",

  serviceBannerTitle: "SERVICES",

  introDescription:
    'After the total solar eclipse event in 1995, Mui Ne was discovered very nicely, can be likened to "sleeping princess in the forest" and tourism investors have woken up the "princess" and brought the pristine natural beauty with the blue sea, white sand, and sunshine to introduce many tourists.',

  firstIntroSection: "5h from Saigon",
  secondIntroSection: " Blue sea, white sand, sunshine",
  thirdIntroSection: "Paradise of the Resort",
  fourthIntroSection: "Abundant local cuisine",
  fifthIntroSection: "The most beautiful road in Vietnam",
  sixthIntroSection: "Wild and spectacular white sand dune",
  seventhIntroSection: "The simple life of a fishing village",
  eighthIntroSection: "Helpful and friendly citizens",

  // **** MENU SECTION ****
  menuHome: "Home",

  menuRoom: "Rooms",

  menuService: "Services",

  menuPackage: "Packages",

  menuGallery: "Gallery",

  menuAbout: "About us",

  menuContact: "Contact us",

  menuSubSlogan: "Welcome to Muine Bay Resort",

  bookNow: "BOOK NOW",

  usefulLink: "Useful Links",

  // **** MENU SECTION ****

  // **** CONTACT SECTION ****
  aboutTitle: "About us",

  officeAddressTitle: "Representative Office Address",

  resortAddressTitle: "Resort Address",

  pageTitle: "Contact",

  contactTitle: "Get in touch",

  contactDescription:
    "An ideal holiday destination for those who would like to hide away from urban life.",

  followTitle: "Follow us",

  messagePlaceholder: "Message",

  muineNamePlaceholder: "Name",

  phoneTitle: "Phone",

  representativeAddress:
    "Representative Office: 203 Dinh Tien Hoang Str., Tan Dinh Ward, District 1, Ho Chi Minh City, Vietnam",

  resortAddress:
    "Quarter 14, Mui Ne Ward , Phan Thiet City, Binh Thuan Province, Vietnam",

  sendButton: "Send Info",

  // ********* COMMON INFO ROOM ************
  additionalOffer: "Additional Offers",

  amenitiesTitle: "Amenities",

  awardsTitle: "Awards",

  bathtubTitle: "Bathtub",

  bedTitle: "Bed",

  hotDealsTitle: "Hot Deals",

  breakfastTitle: "Breakfast",

  cableTVTitle: "Cable TV",

  childPolicyTitle: "Child Policy",

  descriptionTitle: "Description",

  discountTitle: "Discount",

  gradenTitle: "Garden",

  noTitle: "No",

  oceanTitle: "Ocean",

  peopleTitle: "People",

  petTitle: "Pets Allowed",

  roomServiceTitle: "Room Service",

  roomSizeTitle: "Room Size",

  roomSubTitle:
    '"Gorgeous rooms, green landscape, relaxing taste of ocean and every detail reflexes the sense of Champa… Can\'t imagine those single things could be connected marvellously in Muine Bay resort"',

  roomSubTitleComment:
    "Said a well known Actress - Businesswoman stayed in 2012.",

  roomTitle: "Rooms",

  transitCarTitle: "Transit Car",

  viewTitle: "View",

  yesTitle: "Yes",

  addOnsService: "Add-ons Service: ",

  includeTitle: "Inclusions:",

  termsAndConditionsTitle: "Terms and Conditions:",

  readMoreText: "Read more",
};
