const initState = {
  list: [],
  roomDetail: {}
};
function roomReducer(state = initState, action) {
  if (action.type === "SET_ROOMS") {
    return {
      ...state,
      list: action.data
    };
  }
  if (action.type === "SET_ROOM_DETAIL") {
    return {
      ...state,
      roomDetail: action.data
    };
  }

  return state;
}
export default roomReducer;
