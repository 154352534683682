import React from "react";
import Banner from "../Common/Banner";
import { connect } from "react-redux";
import ServicesContainer from "./ServicesContainer";
import Localization from "../Localization/Localize";

const ServicesPage = ({ services, lang }) => {
  return (
    <div>
      <Banner name={Localization.localize("serviceBannerTitle")} />
      <div className="container content-area">
        <div className="middle-align">
          <div className="site-main sitefull">
            {services &&
              services.map((currentService, index) => {
                if (!currentService.isActive) {
                  return null;
                }
                const isLast = (index + 1) % 2 === 0;
                return (
                  <ServicesContainer
                    isLast={isLast}
                    currentService={currentService}
                    keyIdx={index}
                    key={index}
                  />
                );
              })}
          </div>
          <div className="clear"></div>
        </div>
      </div>
    </div>
  );
};

export default connect((state) => {
  return {
    lang: state.lang.currentLang,
    services: state.muineService.list,
  };
})(ServicesPage);
